import React, { lazy, Suspense, memo, useContext } from 'react';

import pages from '../../constants/pages.json';
import { CreditcardContext } from '../../context/creditcard.context';
import { LoadingComponent } from '../../components/loading';
import { isTrue } from '../../utils/functions';

declare const window: any;

interface LayoutForRenderInterface {
  from: string;
  get: (key: string) => string;
  existsWindow: any;
  loading: boolean;
}

const LayoutForRender = memo(
  ({ from, get, existsWindow, loading, ...props }: LayoutForRenderInterface) => {
    if (!existsWindow) {
      return null;
    }

    if (loading) {
      return <LoadingComponent/>;
    }

    const {
      ACCOUNT_LOCK,
      ADDED_NOT_PAID,
      BAD_ASSOCIATION_CARD,
      CVU,
      CVV,
      DUPLICATE_USER,
      DUPLICATED_CARD,
      GENERATE_PIN,
      INVALID_PIN,
      NO_CAMERA,
      NOT_ADDED_NOT_PAID,
      UPDATE_CUSTOMER,
      RECOVER_PIN,
      PHOTO_UNCLEAR,
      DENIED,
      DISALLOWED_HOLDER_NAME,
      ALIAS
    } = pages.FAILED_PAGE;

    const getComponent = (name: string) => (module: any) => ({
      default: module[`Failed${name}Component`],
    });

    const layout = {
      [CVU]: () => import('../../components/failed/cvu').then(getComponent('Cvu')),
      [CVV]: () => import('../../components/failed/cvv').then(getComponent('Cvv')),
      [UPDATE_CUSTOMER]: () => import('../../components/failed/customer').then(getComponent('Customer')),
      [DUPLICATE_USER]: () => import('../../components/failed/duplicate-user').then(getComponent('DuplicateUser')),
      [DUPLICATED_CARD]: () => import('../../components/failed/duplicate-card').then(getComponent('DuplicateCard')),
      [BAD_ASSOCIATION_CARD]: () =>
        import('../../components/failed/bad-association-card').then(getComponent('BadAssociationCard')),
      [NO_CAMERA]: () => import('../../components/failed/no-camera').then(getComponent('NoCamera')),
      [ADDED_NOT_PAID]: () => import('../../components/failed/added-not-paid').then(getComponent('AddedNotPaid')),
      [INVALID_PIN]: () => import('../../components/failed/invalid-pin').then(getComponent('InvalidPin')),
      [ACCOUNT_LOCK]: () => import('../../components/failed/account-lock').then(getComponent('AccountLock')),
      [GENERATE_PIN]: () => import('../../components/failed/generate-pin').then(getComponent('GeneratePin')),
      [RECOVER_PIN]: () => import('../../components/failed/error-recovery-pin').then(getComponent('ValidationPin')),
      [PHOTO_UNCLEAR]: () => import('../../components/failed/photo-unclear').then(getComponent('PhotoUnclear')),
      [DENIED]: () => import('../../components/failed/denied').then(getComponent('Denied')),
      [NOT_ADDED_NOT_PAID]: () => import('../../components/failed/not-added-not-paid').then(getComponent('NotAddedNotPaid')),
      [DISALLOWED_HOLDER_NAME]: () => import('../../components/failed/disallowed-holder-name').then(getComponent('DisallowedHolderName')),
      [ALIAS]: () => import('../../components/failed/alias').then(getComponent('Alias')),
      default: () => import('../../components/failed/new-card').then(getComponent('NewCard')),
    };

    const Template = lazy(() => layout[from]());

    return (
      <Suspense fallback={null}>
        <Template get={get} {...props} />
      </Suspense>
    );
  },
  ({ from: prevFrom, loading: prevLoading }, { from: nextFrom, loading: nextLoading }) => {
    return [prevLoading === nextLoading, prevFrom === nextFrom].every(isTrue);
  }
);

const FailedPage = ({ get, location }: PageProps) => {
  const windowGlobal = typeof window !== 'undefined' && window;
  const { loading } = useContext(CreditcardContext);
  const { state } = location;
  const { from = 'default', ...props } = state ?? windowGlobal?.history?.state ?? {};
  return <LayoutForRender existsWindow={windowGlobal} get={get} from={from} loading={loading} {...props} />;
};

export default FailedPage;
